import React from 'react';

import { Box, BoxProps } from '@allergan-data-labs/alle-elements-layout';

import { CheckSolidCircleIcon } from '@allergan-data-labs/alle-elements-icons/24/checkSolidCircleIcon';
import { CheckOutlineCircleIcon } from '@allergan-data-labs/alle-elements-icons/24/checkOutlineCircleIcon';
import { LockClosedOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/lockClosedOutlineIcon';
import { CircleCheckGold } from '@packages/deprecated-component-library/src/icons/circleCheckGold';
import { AlistPerksContent } from './alistTier/alistTierContent';

const PerksWrapper = ({ children, ...chakraProps }: BoxProps) => {
  return (
    <Box {...chakraProps} display={'block'} marginTop={'24px'}>
      {children}
    </Box>
  );
};

const PerksList = ({ children, ...chakraProps }: BoxProps) => {
  return (
    <Box {...chakraProps} maxWidth={'500px'} padding={'0 16px 24px 16px'}>
      {children}
    </Box>
  );
};

const ListIcon = ({ children, ...chakraProps }: BoxProps) => {
  return (
    <Box {...chakraProps} as="span" marginRight={'16px'}>
      {children}
    </Box>
  );
};

const ListContent = ({ children, ...chakraProps }: BoxProps) => {
  return (
    <Box {...chakraProps} display={'flex'}>
      {children}
    </Box>
  );
};

interface ListContentProps extends BoxProps {
  isAlister?: boolean;
  titleColor: string;
}
const Title = ({
  children,
  isAlister,
  titleColor,
  ...chakraProps
}: ListContentProps) => {
  if (isAlister) {
    return (
      <Box
        {...chakraProps}
        marginBottom={'24px'}
        background={titleColor}
        color="transparent"
        backgroundClip={'text'}
        width={'50%'}
      >
        {children}
      </Box>
    );
  }
  return (
    <Box {...chakraProps} marginBottom={'24px'} color={titleColor}>
      {children}
    </Box>
  );
};

export const AlistPerks = ({
  isAlister,
  isRewardsHubEnabled,
}: {
  isAlister: boolean;
  isRewardsHubEnabled: boolean;
}) => {
  const perks = isAlister
    ? isRewardsHubEnabled
      ? AlistPerksContent.alistPerksAList
      : AlistPerksContent.alistPerksAListPointsBankingDisabled
    : isRewardsHubEnabled
    ? AlistPerksContent.perks
    : AlistPerksContent.perksPointsBankingDisabled;
  const secondPerks = isAlister
    ? isRewardsHubEnabled
      ? AlistPerksContent.perks
      : AlistPerksContent.perksPointsBankingDisabled
    : isRewardsHubEnabled
    ? AlistPerksContent.alistPerksMember
    : AlistPerksContent.alistPerksMemberPointsBankingDisabled;
  const title = isAlister
    ? AlistPerksContent.alistTitle
    : AlistPerksContent.title;
  const secondTitle = isAlister
    ? AlistPerksContent.secondAlistTitle
    : AlistPerksContent.secondTitle;
  const textColor = isAlister
    ? AlistPerksContent.alistTextColor
    : AlistPerksContent.textColor;
  const titleColor = isAlister
    ? AlistPerksContent.titleColor
    : AlistPerksContent.textColor;

  const renderPerk = (perk: string) => {
    if (perk.includes('VIP Member support') && isAlister) {
      const parts = perk.split(' at ');
      return (
        <span>
          {parts[0]} at{' '}
          <a
            href="tel:1-888-281-1849"
            css={{ textDecoration: 'underline', color: 'white' }}
            target={'_blank'}
            rel="noreferrer"
          >
            1.888.281.1849
          </a>
        </span>
      );
    }
    return <span>{perk}</span>;
  };

  return (
    <PerksWrapper>
      <PerksList>
        <Title
          data-testid={'perks-title'}
          textStyle={'Body/Large/Medium'}
          isAlister={isAlister}
          titleColor={titleColor}
        >
          {title}
        </Title>
        {perks.map((perk, index) => (
          <ListContent key={`perk-${index}`}>
            <ListIcon>
              {isAlister ? (
                <CircleCheckGold />
              ) : (
                <CheckSolidCircleIcon sx={{ color: '#9A6B5E' }} />
              )}
            </ListIcon>
            <Box
              data-testid={`perk-list`}
              textStyle={'Body/Medium/Regular'}
              marginBottom={'16px'}
              display={'inline'}
              color={textColor}
            >
              {renderPerk(perk)}
            </Box>
          </ListContent>
        ))}
      </PerksList>
      <PerksList>
        <Box
          data-testid={'perks-second-title'}
          textStyle={'Body/Large/Medium'}
          textColor={textColor}
          marginBottom={'24px'}
        >
          {secondTitle}
        </Box>
        {secondPerks.map((perk, index) => (
          <ListContent key={`perk-${index}`}>
            <ListIcon>
              {isAlister ? (
                <CheckOutlineCircleIcon sx={{ color: '#B3B0AE' }} />
              ) : (
                <LockClosedOutlineIcon sx={{ color: '#B3B0AE' }} />
              )}
            </ListIcon>
            <Box
              data-testid={`second-perk-list-${index}`}
              textStyle={'Body/Medium/Regular'}
              marginBottom={'16px'}
              display={'inline'}
              color={textColor}
            >
              {renderPerk(perk)}
            </Box>
          </ListContent>
        ))}
      </PerksList>
    </PerksWrapper>
  );
};
