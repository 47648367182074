import React from 'react';
import { SystemStyleObject, keyframes, forwardRef } from '@chakra-ui/react';

import {
  ColorMode,
  useColorMode,
  getColorToken,
  pxToRem,
} from '@allergan-data-labs/alle-elements-core';
import { Box } from '@allergan-data-labs/alle-elements-layout';

export interface ProgressBarProps {
  /** If true, the progress bar will animate for every change in value
   * @default true
   */
  animate?: boolean;

  /** The color mode that the progress bar will display.
   * @default light
   */
  colorMode?: ColorMode;

  /** The visual color appearance of the progress bar
   * @default 'action'
   */
  colorScheme?: ProgressColorScheme;

  /** The the shape of the beginning and end of the progress bar.
   * @default 'round'
   */
  endCapShape?: EndCapShape;

  /** If true, the progress bar will animate continuously. */
  isIndeterminate?: boolean;

  /** The progress bar thickness in pixels.
   * @default 4
   */
  height?: number;

  /** The value of the progress bar. If undefined the progress bar will be in indeterminate state */
  value?: number;

  /** Width of the progress bar inside its parent container
   * @default 100%
   */
  width?: string;
}

const getBackgroundColor = (colorScheme: ProgressColorScheme): string => {
  switch (colorScheme) {
    case 'member':
      return 'Border/Neutral/Subtle 2';
    case 'a_list':
      return '#FFFFFF33';
    default:
      return 'Border/Neutral/Subtle 2';
  }
};

const getBarColor = (colorScheme: ProgressColorScheme): string => {
  switch (colorScheme) {
    case 'member':
      return 'Icon/Brand/Default';
    case 'a_list':
      return 'linear-gradient(to right, #8B6A2B, #F8D98B, #836428)';
    default:
      return 'Icon/Brand/Default';
  }
};

function calculateWidth(
  isIndeterminate: boolean | undefined,
  value: number | undefined
) {
  if (isIndeterminate || value === undefined) {
    return '40%';
  } else {
    const validValue = Math.min(100, Math.max(0, value));
    return `${validValue}%`;
  }
}
export const getContainerStyles = ({
  height = 4,
  width = '100%',
  colorScheme = 'member',
  colorMode = 'light',
  endCapShape = 'round',
}: {
  height?: number;
  width?: string;
  colorScheme?: ProgressColorScheme;
  colorMode?: ColorMode;
  endCapShape?: EndCapShape;
}): SystemStyleObject => {
  return {
    height: pxToRem(`${height}px`),
    width: width,
    background:
      colorScheme === 'a_list'
        ? getBackgroundColor(colorScheme)
        : getColorToken(getBackgroundColor(colorScheme), colorMode),
    borderRadius: endCapShape === 'round' ? `${height / 2}` : '0',
    overflow: 'hidden',
  };
};

export const getBarStyle = ({
  animate = true,
  colorScheme = 'member',
  colorMode = 'light',
  endCapShape = 'round',
  height = 4,
  value,
  isIndeterminate,
}: {
  animate?: boolean;
  colorScheme?: ProgressColorScheme;
  colorMode?: ColorMode;
  endCapShape?: EndCapShape;
  width?: string;
  height?: number;
  value?: number;
  isIndeterminate?: boolean;
}): SystemStyleObject => {
  const slideRight = keyframes`
  0% {
    transform:  translateX(-100%);
  }
  100% {
    transform:  translateX(250%);
  }
`;
  const animation =
    isIndeterminate || value === undefined
      ? `${slideRight} 2s infinite linear`
      : 'none';

  return {
    height: pxToRem(`${height}px`),
    width: calculateWidth(isIndeterminate, value),
    background:
      colorScheme === 'a_list'
        ? getBarColor(colorScheme)
        : getColorToken(getBarColor(colorScheme), colorMode),
    borderRadius: endCapShape === 'round' ? `${height / 2}` : '0',
    overflow: 'hidden',
    transition: animate ? 'width .25s' : 'none',
    animation: animation,
  };
};

export type EndCapShape = 'round' | 'flat';
export type ProgressColorScheme = 'member' | 'a_list';

/**
 * Progress bar is used to display the progress status for a task that takes a long time or consists of several steps.
 */

export const ProgressBar = forwardRef<ProgressBarProps, 'div'>(
  (
    {
      animate,
      colorScheme,
      colorMode: propColorMode,
      endCapShape,
      height,
      isIndeterminate,
      value,
      width = '100%',
      ...rest
    }: ProgressBarProps,
    ref
  ) => {
    const { colorMode } = useColorMode(propColorMode);

    return (
      <Box
        ref={ref}
        role="progressbar"
        aria-label={`progressbar-${colorScheme}`}
        aria-busy={isIndeterminate || value === undefined}
        aria-valuenow={isIndeterminate ? 0 : value}
        aria-valuemin={0}
        aria-valuemax={100}
        data-testid={'progress-bar-background'}
        sx={getContainerStyles({
          colorMode,
          colorScheme,
          endCapShape,
          height,
          width,
        })}
        {...rest}
      >
        <Box
          data-testid={'progress-bar-foreground'}
          sx={getBarStyle({
            animate,
            colorMode,
            colorScheme,
            endCapShape,
            isIndeterminate,
            height,
            value,
            width,
          })}
        />
      </Box>
    );
  }
);
