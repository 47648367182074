import React from 'react';

// shared components
import { ReactLazyPreload } from '@packages/deprecated-component-library/src/core/preload';

// screens without lazy loading
import { AuthCallbackView } from './views/authCallbackView/authCallbackView';
import { LogoutView } from './views/logout/logoutView';

// components
import { HeaderDynamic } from './components/headers/headerDynamic';
import { HeaderWithClose } from './components/headers/headerWithClose';

// constants
import { __CMS_PREVIEW__ } from './application.config';
import { Routes } from './constants/routes';

export interface RouteConfig {
  path: Routes;
  title?: string;
  header?: React.FunctionComponent<any>;
  component: React.FunctionComponent<any>;
  secure: boolean;
  hideFooter?: boolean;
  exact: boolean;
  ssr?: boolean;
}

const AboutView = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'AboutView' */ './views/about/aboutView').then(
    (mod) => ({
      default: mod.AboutView,
    })
  )
);
const HelpView = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'HelpView' */ './views/help/helpView').then(
    (mod) => ({
      default: mod.HelpView,
    })
  )
);
const HomeSwitch = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'HomeSwitch' */ './views/home/homeSwitch').then(
    (mod) => ({
      default: mod.HomeSwitch,
    })
  )
);
const AccountDashboardView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AccountDashboardView' */ './views/account/accountDashboardView'
  ).then((mod) => ({
    default: mod.AccountDashboardView,
  }))
);
const AccountWalletHandler = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AccountWalletView' */ './views/account/wallet/accountWalletView'
  ).then((mod) => ({
    default: mod.AccountWalletView,
  }))
);

const BotoxDayLandingPageRouter = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BotoxDayLandingPageRouter' */ './views/botoxCosmeticDay/botoxDayLandingPageRouter'
  ).then((mod) => ({
    default: mod.BotoxDayLandingPageRouter,
  }))
);
const ForgotPasswordView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ForgotPasswordView' */ './views/forgotPassword/forgotPasswordView'
  ).then((mod) => ({
    default: mod.ForgotPasswordView,
  }))
);
const GetStartedView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'GetStartedView' */ './views/getStarted/getStartedView'
  ).then((mod) => ({
    default: mod.GetStartedView,
  }))
);
const TermsAndConditionsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'TermsAndConditionsView' */ './views/legal/termsAndConditionsView'
  ).then((mod) => ({
    default: mod.TermsAndConditionsView,
  }))
);

const PointsCapView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PointsCapView' */ './views/legal/pointsCapView'
  ).then((mod) => ({
    default: mod.PointsCapView,
  }))
);

const PatientOfferDetailsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientOfferDetailsView' */ './views/patientOfferDetails/patientOfferDetailsView'
  ).then((mod) => ({
    default: mod.PatientOfferDetailsView,
  }))
);

const LoginView = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'LoginView' */ './views/login/loginView').then(
    (mod) => ({
      default: mod.LoginView,
    })
  )
);
const MigrationCampaignView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'UpgradeView' */ './views/upgrade/upgradeView'
  ).then((mod) => ({
    default: mod.UpgradeView,
  }))
);
const OptInOfferRouterContentful = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OptInOfferRouterContentful' */ './views/offer/optInOfferRouterContentful'
  ).then((mod) => ({
    default: mod.OptInOfferRouterContentful,
  }))
);
const OptInOfferClaimView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OptInOfferClaimView' */ './views/offerClaim/optInOfferClaimView'
  ).then((mod) => ({
    default: mod.OptInOfferClaimView,
  }))
);
const GrowOfferActivationView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'GrowOfferActivationView' */ './views/grow/offerActivationView'
  ).then((mod) => ({
    default: mod.OfferActivationView,
  }))
);
const PaymentPlansRedirect = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PaymentPlans' */ './views/payment-plans/paymentPlans'
  ).then((mod) => ({
    default: mod.PaymentPlansRedirect,
  }))
);
const PrintHIPAAView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PrintHIPAAView' */ './views/legal/printHIPAAView'
  ).then((mod) => ({
    default: mod.PrintHIPAAView,
  }))
);
const SearchResultsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SearchResultsView' */ './views/search/results/searchResultsView'
  ).then((mod) => ({
    default: mod.SearchResultsView,
  }))
);
const SearchProfileView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SearchProfileView' */ './views/search/profile/searchProfileView'
  ).then((mod) => ({
    default: mod.SearchProfileView,
  }))
);
const RegistrationPhoneNumberView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'RegistrationPhoneNumberView' */ './views/registration/registrationPhoneNumberView'
  ).then((mod) => ({
    default: mod.RegistrationPhoneNumberView,
  }))
);
const TestMockHeaders = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'TestMockHeaders' */ './views/registration/testMockHeaders'
  ).then((mod) => ({
    default: mod.TestMockHeaders,
  }))
);
const RegistrationView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'RegistrationView' */ './views/registration/registrationView'
  ).then((mod) => ({
    default: mod.RegistrationView,
  }))
);
const ResetPasswordWithTokenView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ResetPasswordWithTokenView' */ './views/resetPassword/resetPasswordWithTokenView'
  ).then((mod) => ({
    default: mod.ResetPasswordWithTokenView,
  }))
);
const ResetPasswordWithCodeView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ResetPasswordWithCodeView' */ './views/resetPassword/resetPasswordWithCodeView'
  ).then((mod) => ({
    default: mod.ResetPasswordWithCodeView,
  }))
);
const VerifyEmailView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'VerifyEmailView' */ './views/verifyEmail/verifyEmailView'
  ).then((mod) => ({
    default: mod.VerifyEmailView,
  }))
);
const VerifyPhoneChangeView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'VerifyPhoneChangeView' */ './views/verifyPhoneChange/verifyPhoneChangeView'
  ).then((mod) => ({
    default: mod.VerifyPhoneChangeView,
  }))
);
const TreatmentGuideSwitch = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'TreatmentGuideSwitch' */ './views/treatment-guides/treatmentGuideSwitch'
  ).then((mod) => ({
    default: mod.TreatmentGuideSwitch,
  }))
);

const ContentfulArticleRouter = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ContentfulArticleRouter' */ './views/articles/contentfulArticleRouter'
  ).then((mod) => ({
    default: mod.ContentfulArticleRouter,
  }))
);

const ArticlePreview = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ArticleContentfulPreview' */ './views/articles/contentfulPreview/articlePageContentfulPreview'
  ).then((mod) => ({
    default: mod.ArticlePageContentfulPreview,
  }))
);

const ArticleContentfulPreviewTabs = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ArticleContentfulPreviewTabs' */ './views/articles/contentfulPreview/articleContentfulPreviewTabs'
  ).then((mod) => ({
    default: mod.ArticleContentfulPreviewRouter,
  }))
);

const LandingPageRouter = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'LandingPageRouter' */ './views/landingPages/landingPageRouter'
  ).then((mod) => ({
    default: mod.LandingPageRouter,
  }))
);

const LandingPagePreview = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'LandingPagePreview' */ './views/landingPages/landingPagePreview'
  ).then((mod) => ({
    default: mod.LandingPagePreview,
  }))
);

const LandingPagePreviewTabs = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'LandingPagePreviewTabs' */ './views/landingPages/landingPagePreviewTabs'
  ).then((mod) => ({
    default: mod.LandingPagePreviewTabs,
  }))
);

const BusinessLandingPagePreview = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BusinessLandingPagePreview' */ './views/landingPages/businessPreview/businessLandingPagePreview'
  ).then((mod) => ({
    default: mod.BusinessLandingPagePreview,
  }))
);

const ProviderSearchLandingPageRouter = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ProviderSearchLandingPageRouter' */ './views/provider-search/providerSearchLandingPageRouter'
  ).then((mod) => ({
    default: mod.ProviderSearchLandingPageRouter,
  }))
);

const ProviderSearchLandingPagePreview = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ProviderSearchLandingPagePreview' */ './views/provider-search/providerSearchLandingPagePreview'
  ).then((mod) => ({
    default: mod.ProviderSearchLandingPagePreview,
  }))
);

const BeforeAndAfterPreview = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BeforeAndAfterPreview' */ './components/contentful/beforeAndAfterPreview'
  ).then((mod) => ({
    default: mod.BeforeAndAfterPreview,
  }))
);

const OptInOfferLandingPagePreview = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OptInOfferViewContentfulPreview' */ './views/offer/contentfulPreview/optInOfferViewContentfulPreview'
  ).then((mod) => ({
    default: mod.OptInOfferViewContentfulPreview,
  }))
);

const OptInOfferLandingPagePreviewTabs = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OptInOfferContentfulPreviewTabs' */ './views/offer/contentfulPreview/optInOfferContentfulPreviewTabs'
  ).then((mod) => ({
    default: mod.OptInOfferContentfulPreviewTabsRouter,
  }))
);

const GlobalIsiView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'GlobalIsiView' */ './views/globalIsi/globalIsiView'
  ).then((mod) => ({
    default: mod.GlobalIsiView,
  }))
);

const OurBrandsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OurBrandsView' */ './views/our-brands/ourBrandsView'
  ).then((mod) => ({
    default: mod.OurBrandsView,
  }))
);

const DiscoverView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'DiscoverView' */ './views/articles/discoverView'
  ).then((mod) => ({
    default: mod.DiscoverView,
  }))
);

const ThrowErrorView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ViewWillThrowError' */ './views/debugViews/throwError'
  ).then((mod) => ({
    default: mod.ViewWillThrowError,
  }))
);

const HowItWorksView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'HowItWorksView' */ './views/howItWorks/howItWorksView'
  ).then((mod) => ({
    default: mod.HowItWorksView,
  }))
);

const BotoxCastingView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BotoxCastingView' */ './views/botoxCosmeticCasting/botoxCastingView'
  ).then((mod) => ({
    default: mod.BotoxCastingView,
  }))
);

const AppRequiredView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AppRequiredView' */ './views/appRequired/appRequiredView'
  ).then((mod) => ({
    default: mod.AppRequiredView,
  }))
);

const ReferAFriendView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ReferAFriendView' */ './views/referAFriend/referAFriendView'
  ).then((mod) => ({
    default: mod.ReferAFriendView,
  }))
);

const GrowOptOutView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'GrowOptOutView' */ './views/optOut/OptOutView'
  ).then((mod) => ({
    default: mod.OptOutView,
  }))
);

const SplitIntegration = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SplitIntegrationView' */ './views/debugViews/splitIntegration'
  ).then((mod) => ({
    default: mod.SplitIntegrationView,
  }))
);

const DebugDeviceLocation = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'DebugDeviceLocation' */ './views/debugViews/debugDeviceLocation'
  ).then((mod) => ({
    default: mod.DebugDeviceLocation,
  }))
);

const NotificationsAndPrivacyView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'NotificationsAndPrivacyView' */ './views/preferences/notificationsAndPrivacy/notificationsAndPrivacyView'
  ).then((mod) => ({
    default: mod.NotificationsAndPrivacyView,
  }))
);

const EmailPreferences = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'EmailPreferencesView' */ './views/preferences/emailPreferences'
  ).then((mod) => ({
    default: mod.EmailPreferences,
  }))
);

const SmsPreferences = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'SmsPreferencesView' */ './views/preferences/smsPreferences'
  ).then((mod) => ({
    default: mod.SmsPreferences,
  }))
);

const LoginSettings = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'LoginSettings' */ './views/preferences/login-settings/loginSettings'
  ).then((mod) => ({
    default: mod.LoginSettings,
  }))
);

const PrivacySettingsRouter = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PrivacySettingsRouter' */ './views/preferences/privacySettings/privacySettingsRouter'
  ).then((mod) => ({
    default: mod.PrivacySettingsRouter,
  }))
);

const ProfileInfo = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ProfileInfo' */ './views/preferences/profileInfo/profileInfoView'
  ).then((mod) => ({
    default: mod.ProfileInfoView,
  }))
);

const FinancingLandingPageRedirectView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'FinancingLandingPageRedirectView' */ './views/payment-plans/financingLandingPageRedirect'
  ).then((mod) => ({
    default: mod.FinancingLandingPageRedirect,
  }))
);

const CherryFinancingApplicationView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'cherryFinancingApplicationView' */ './views/payment-plans/application/cherryFinancingApplicationView'
  ).then((mod) => ({
    default: mod.CherryFinancingApplicationView,
  }))
);

const JuvedermDayLandingPageRouter = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'JuvedermDayLandingPageRouter' */ './views/juvedermDay/JuvedermDayLandingPageRouter'
  ).then((mod) => ({
    default: mod.JuvedermDayLandingPageRouter,
  }))
);

const BrowseStatesView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BrowseStatesView' */ './views/search/browse/browseStatesView'
  ).then((mod) => ({
    default: mod.BrowseStatesView,
  }))
);

const BrowseCitiesView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BrowseCitiesView' */ './views/search/browse/browseCitiesView'
  ).then((mod) => ({
    default: mod.BrowseCitiesView,
  }))
);

const BrowseCityView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BrowseCityView' */ './views/search/browse/browseCityView'
  ).then((mod) => ({
    default: mod.BrowseCityView,
  }))
);

const DiscoverLocationView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'DiscoverLocationView' */ './views/search/discover/discoverLocationView'
  ).then((mod) => ({
    default: mod.DiscoverLocationView,
  }))
);

const TreatmentsHistoryView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'TreatmentsHistoryView' */ './views/treatmentsHistory/treatmentsHistoryView'
  ).then((mod) => ({
    default: mod.TreatmentsHistoryView,
  }))
);

const DiscoverProductTreatmentView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'DiscoverProductTreatmentView' */ './views/search/discover/discoverProductTreatmentView'
  ).then((mod) => ({
    default: mod.DiscoverProductTreatmentView,
  }))
);

const RewardsLandingPageView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'RewardsLandingPageView' */ './views/rewards/rewardsLandingPageView'
  ).then((mod) => ({
    default: mod.RewardsLandingPageView,
  }))
);

const ImportantSafetyInformationView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ImportantSafetyInformationView' */ './views/importantSafetyInformation/importantSafetyInformationView'
  ).then((mod) => ({
    default: mod.ImportantSafetyInformationView,
  }))
);

const cmsPreviewRoutes: RouteConfig[] = [
  {
    path: Routes.articlePreviewTabs,
    component: ArticleContentfulPreviewTabs,
    secure: false,
    hideFooter: false,
    exact: true,
  },
  {
    path: Routes.articlePreview,
    header: (props) => (
      <HeaderDynamic keepPathOnLogin jumpLink={'#joinBanner'} {...props} />
    ),
    component: ArticlePreview,
    secure: false,
    exact: true,
  },
  {
    path: Routes.beforeAndAfterPreview,
    component: BeforeAndAfterPreview,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.landingPagePreview,
    component: LandingPagePreview,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.businessLandingPagePreview,
    component: BusinessLandingPagePreview,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.landingPagePreviewTabs,
    component: LandingPagePreviewTabs,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.providerSearchLandingPagePreview,
    component: ProviderSearchLandingPagePreview,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.optInOfferLandingPagePreview,
    component: OptInOfferLandingPagePreview,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.optInOfferLandingPagePreviewTabs,
    component: OptInOfferLandingPagePreviewTabs,
    secure: false,
    hideFooter: true,
    exact: true,
  },
];

const baseUnsecureRoutes: RouteConfig[] = [
  {
    path: Routes.debugThrownErrors,
    title: 'DEBUG - Error',
    header: HeaderDynamic,
    component: ThrowErrorView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.debugSplitIntegration,
    title: 'DEBUG - Split Integration',
    header: () => <div></div>,
    component: SplitIntegration,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.debugDeviceLocation,
    title: 'DEBUG - Device Location',
    header: () => <div></div>,
    component: DebugDeviceLocation,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.about,
    title: 'About Allē',
    header: HeaderDynamic,
    component: AboutView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.home,
    title: 'Allē - A loyalty program uniquely designed for you.',
    header: (props) => (
      <HeaderDynamic
        loggedOutVariant="light"
        {...props}
        layoutVariant="navigator"
      />
    ),
    component: HomeSwitch,
    secure: false,
    exact: true,
  },
  {
    path: Routes.homeIndex,
    title: 'Allē - A loyalty program uniquely designed for you.',
    header: (props) => (
      <HeaderDynamic
        loggedOutVariant="light"
        {...props}
        layoutVariant="navigator"
      />
    ),
    component: HomeSwitch,
    secure: false,
    exact: true,
  },
  {
    path: Routes.help,
    title: 'Contact Us',
    header: HeaderDynamic,
    component: HelpView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.login,
    title: 'Login',
    header: HeaderWithClose,
    component: LoginView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.logout,
    title: 'Logout',
    header: undefined,
    component: LogoutView,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.authCallback,
    title: 'Login',
    header: undefined,
    component: AuthCallbackView,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.getStarted,
    title: 'Get Started',
    header: HeaderWithClose,
    component: GetStartedView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.termsAndConditions,
    title: 'Terms and Conditions',
    header: HeaderDynamic,
    component: TermsAndConditionsView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.pointsAndEarning,
    title: 'Points and Earning',
    header: HeaderDynamic,
    component: PointsCapView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.patientOfferDetails,
    title: 'Offer Details',
    header: HeaderDynamic,
    component: PatientOfferDetailsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.printHIPAA,
    title: 'Allē',
    header: undefined,
    component: PrintHIPAAView,
    secure: false,
    exact: true,
    hideFooter: true,
  },
  {
    path: Routes.providerSearch,
    title: 'Directory - Allē',
    header: (props) => (
      <div style={{ position: 'relative', zIndex: 2 }}>
        <HeaderDynamic keepPathOnLogin compact {...props} />
      </div>
    ),
    component: SearchResultsView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.providerProfile,
    header: (props) => <HeaderDynamic keepPathOnLogin compact {...props} />,
    component: SearchProfileView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.browseStates,
    title: 'Browse',
    header: (props) => <HeaderDynamic keepPathOnLogin compact {...props} />,
    component: BrowseStatesView,
    secure: false,
    exact: true,
    ssr: true,
  },
  {
    path: Routes.browseCities,
    title: 'Browse Cities',
    header: (props) => <HeaderDynamic keepPathOnLogin compact {...props} />,
    component: BrowseCitiesView,
    secure: false,
    exact: true,
    ssr: true,
  },
  {
    path: Routes.browseCity,
    title: 'Browse City',
    header: (props) => <HeaderDynamic keepPathOnLogin compact {...props} />,
    component: BrowseCityView,
    secure: false,
    exact: true,
    ssr: true,
  },
  {
    path: Routes.discoverLocation,
    title: 'Discover Location',
    header: (props) => <HeaderDynamic keepPathOnLogin compact {...props} />,
    component: DiscoverLocationView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.discoverProductOrTreatment,
    title: 'Discover Location',
    header: (props) => <HeaderDynamic keepPathOnLogin compact {...props} />,
    component: DiscoverProductTreatmentView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.registration,
    title: 'Registration',
    header: HeaderWithClose,
    component: RegistrationView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.registrationComplete,
    title: 'Registration',
    header: HeaderWithClose,
    component: RegistrationView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.signup,
    title: 'Get Started',
    header: HeaderWithClose,
    component: GetStartedView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.register,
    title: 'Get Started',
    header: HeaderWithClose,
    component: GetStartedView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.registrationPhoneNumber,
    title: 'Registration',
    header: HeaderWithClose,
    component: RegistrationPhoneNumberView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.testMockHeaders,
    title: 'Test Mock Headers',
    header: HeaderWithClose,
    component: TestMockHeaders,
    secure: false,
    exact: true,
  },
  {
    path: Routes.forgotPassword,
    title: 'Forgot Password',
    header: HeaderWithClose,
    component: ForgotPasswordView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.resetPassword,
    title: 'Reset Password',
    header: HeaderWithClose,
    component: ResetPasswordWithTokenView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.userResetPassword,
    title: 'Reset Password',
    header: HeaderWithClose,
    component: ResetPasswordWithCodeView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.verifyEmail,
    header: HeaderWithClose,
    component: VerifyEmailView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.verifyPhoneChange,
    header: HeaderWithClose,
    component: VerifyPhoneChangeView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.treatmentGuide,
    header: (props) => <HeaderDynamic keepPathOnLogin {...props} />,
    component: TreatmentGuideSwitch,
    secure: false,
    exact: true,
  },
  {
    path: Routes.upgrade,
    header: (props) => (
      <HeaderDynamic loggedOutVariant="transparent" {...props} />
    ),
    component: MigrationCampaignView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.article,
    header: (props) => <HeaderDynamic keepPathOnLogin {...props} />,
    component: ContentfulArticleRouter,
    secure: false,
    exact: true,
  },
  {
    path: Routes.contentfulLandingPage,
    component: LandingPageRouter,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.sweepsLandingPage,
    component: (props) => (
      <LandingPageRouter {...props} landingPageType={'Sweepstakes'} />
    ),
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.juvedermDay,
    component: (props) => <JuvedermDayLandingPageRouter {...props} />,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.mothersDay,
    component: (props) => (
      <LandingPageRouter
        {...props}
        landingPageType={'Tentpole'}
        forceSlug={'mothersday'}
      />
    ),
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.castingcall,
    component: (props) => (
      <LandingPageRouter
        {...props}
        landingPageType={'Tentpole'}
        forceSlug={'castingcall'}
      />
    ),
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.providerSearchLandingPage,
    component: ProviderSearchLandingPageRouter,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.globalIsi,
    title: 'Important Safety Information',
    header: (props) => (
      <HeaderDynamic loggedOutVariant="transparentBlackText" {...props} />
    ),
    component: GlobalIsiView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.ourBrands,
    title: 'Our Brands',
    header: HeaderDynamic,
    component: OurBrandsView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.articles,
    title: 'Discover',
    header: HeaderDynamic,
    component: DiscoverView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.optInOffer,
    title: 'Allē Offer',
    header: undefined,
    component: OptInOfferRouterContentful,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.howItWorks,
    title: 'Allē - How It Works',
    header: HeaderDynamic,
    component: HowItWorksView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.botoxCosmeticCasting,
    title: 'Allē',
    header: undefined,
    component: BotoxCastingView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.appRequired,
    title: 'Allē - App Required',
    header: HeaderDynamic,
    component: AppRequiredView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.referAFriendWithCode,
    title: 'Allē - Refer A Friend',
    header: HeaderDynamic,
    component: ReferAFriendView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.referAFriend,
    title: 'Allē - Refer A Friend',
    header: HeaderDynamic,
    component: ReferAFriendView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.natrelleOffer,
    title: 'Allē - App Required',
    header: HeaderDynamic,
    component: AppRequiredView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.optOutConfirmation,
    title: 'Consumer Opt Out',
    component: GrowOptOutView,
    exact: true,
    hideFooter: true,
    secure: false,
  },
  {
    path: Routes.offerActivation,
    title: 'Offer Activation',
    component: GrowOfferActivationView,
    exact: true,
    hideFooter: true,
    secure: false,
  },
  {
    path: Routes.rewards,
    title: 'Allē - Rewards',
    component: RewardsLandingPageView,
    header: undefined,
    exact: true,
    hideFooter: true,
    secure: false,
  },
  {
    path: Routes.importantSafetyInformation,
    title: 'Allē - Important Safety Information',
    component: ImportantSafetyInformationView,
    header: (props) => <HeaderDynamic compact {...props} />,
    exact: true,
    hideFooter: false,
    secure: false,
  },
];

const unsecureRoutes: RouteConfig[] = __CMS_PREVIEW__
  ? [...baseUnsecureRoutes, ...cmsPreviewRoutes]
  : baseUnsecureRoutes;

// in alphabetical order by PATH
const secureRoutes: RouteConfig[] = [
  {
    path: Routes.accountDashboard,
    title: 'Home',
    header: HeaderDynamic,
    component: AccountDashboardView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.accountWallet,
    title: 'Wallet',
    header: HeaderDynamic,
    component: AccountWalletHandler,
    secure: true,
    exact: true,
  },
  {
    path: Routes.botoxCosmeticDay,
    title: 'Botox Cosmetic Day',
    header: undefined,
    component: BotoxDayLandingPageRouter,
    secure: false,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.financingLandingPageRedirect,
    title: 'Financing',
    header: undefined,
    component: FinancingLandingPageRedirectView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.paymentPlansRedirect,
    title: 'Payment Plans',
    header: undefined,
    component: PaymentPlansRedirect,
    secure: false,
    exact: true,
  },
  {
    path: Routes.financingApplication,
    title: 'Financing',
    component: CherryFinancingApplicationView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.optInAdvertisingOfferClaim,
    title: 'Claim Offer',
    header: HeaderWithClose,
    component: OptInOfferClaimView,
    secure: true,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.optInOfferClaim,
    title: 'Claim Offer',
    header: HeaderWithClose,
    component: OptInOfferClaimView,
    secure: true,
    hideFooter: true,
    exact: true,
  },
  {
    path: Routes.settingsCommunication,
    title: 'Notifications & Privacy',
    header: HeaderDynamic,
    component: NotificationsAndPrivacyView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.settingsEmail,
    title: 'Email Settings',
    header: HeaderDynamic,
    component: EmailPreferences,
    secure: true,
    exact: true,
  },
  {
    path: Routes.settingsProfileInfo,
    title: 'Profile Info Settings',
    header: HeaderDynamic,
    component: ProfileInfo,
    secure: true,
    exact: true,
  },
  {
    path: Routes.settingsPrivacy,
    title: 'Privacy Settings',
    header: HeaderDynamic,
    component: PrivacySettingsRouter,
    secure: true,
    exact: true,
  },
  {
    path: Routes.settingsSms,
    title: 'SMS Settings',
    header: HeaderDynamic,
    component: SmsPreferences,
    secure: true,
    exact: true,
  },
  {
    path: Routes.settingsLogin,
    title: 'Login & Security',
    header: HeaderDynamic,
    component: LoginSettings,
    secure: true,
    exact: true,
  },
  {
    path: Routes.treatments,
    title: 'SMS Settings',
    header: HeaderDynamic,
    component: TreatmentsHistoryView,
    secure: true,
    exact: true,
  },
];
const routeConfig: RouteConfig[] = [...unsecureRoutes, ...secureRoutes];

export { routeConfig };
