import React from 'react';

import { Box, BoxProps } from '@allergan-data-labs/alle-elements-layout';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { ChevronRightIcon } from '@allergan-data-labs/alle-elements-icons/24/chevronRightIcon';

import { AlistPerksContent } from './alistTier/alistTierContent';

const FooterWrapper = ({ children, ...chakraProps }: BoxProps) => {
  return (
    <Box
      {...chakraProps}
      display={{ base: 'flex', md: 'block' }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {children}
    </Box>
  );
};

const FooterContent = ({ children, ...chakraProps }: BoxProps) => {
  return (
    <Box
      {...chakraProps}
      boxSizing={'border-box'}
      width={'100%'}
      padding={{ base: '8px 16px 24px', md: '8px 16px 8px' }}
    >
      {children}
    </Box>
  );
};

const AlistTrackerFooter = ({ isAlister }: { isAlister: boolean }) => {
  const { Link } = useEnvironmentContext();

  const textColor = isAlister
    ? AlistPerksContent.alistTextColor
    : AlistPerksContent.textColor;
  const borderColor = isAlister ? '#FFFFFF14' : '#09090914';
  const chevronColor = isAlister ? '#FFFFFF' : '#090909';

  return (
    <FooterWrapper>
      <FooterContent>
        <Box
          data-testid={'perks-footer-title'}
          textStyle={'Headline/Extra small/Primary medium'}
          textColor={textColor}
          padding={'0 0 24px'}
        >
          Helpful links
        </Box>

        <Link
          href={'https://alle.com/article/a-list-benefits'}
          target={'_blank'}
          isStandalone
        >
          <Box
            data-testid={'perks-link'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            padding={'24px 16px 24px 0'}
            borderTop={`1px solid ${borderColor}`}
            borderBottom={`1px solid ${borderColor}`}
          >
            <Box textStyle={'Body/Medium/Regular'} textColor={textColor}>
              How does A-List work?
            </Box>
            <ChevronRightIcon color={chevronColor} />
          </Box>
        </Link>
      </FooterContent>
    </FooterWrapper>
  );
};

export { AlistTrackerFooter };
