import React, { DragEvent, PointerEvent, useState } from 'react';
import { motion, PanInfo, useAnimation, useDragControls } from 'framer-motion';

// chakra-ui
import { DrawerBody, DrawerHeader } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { Drawer, DrawerOverlay, DrawerContent } from '@chakra-ui/react';

// alle-elements
import { Box, Flex } from '@allergan-data-labs/alle-elements-layout';
import { getColorToken } from '@allergan-data-labs/alle-elements-core';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@allergan-data-labs/alle-elements-modal';
import { Avatar } from '@allergan-data-labs/alle-elements-avatar';
import { ChevronRightIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronRightIcon';
import { LogoutIcon } from '@allergan-data-labs/alle-elements-icons/20/logoutIcon';
import { CloseIcon } from '@allergan-data-labs/alle-elements-icons/24/closeIcon';
import { IconButton } from '@allergan-data-labs/alle-elements-button';

// core
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import {
  Consumer_Tier,
  ConsumerProfile,
} from '@packages/core-alle-graphql-types';

// local
import { AListTier } from '../../components/drawer/myProfile/alistTier';
import { Routes } from '../../constants/routes';
import { getAListTierProgressType } from '../../components/drawer/myProfile/tier/alistTier/alistHelpers';
import { ConsumerTierAListTypes } from '../../components/drawer/myProfile/tier/alistTier/alistTypes';

type HeaderProfileModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  profile: Partial<ConsumerProfile>;
  onOpenAListDialog?: () => void;
};

const DrawerMotion = motion(DrawerContent);

const HeaderProfileModal = ({
  isOpen = false,
  onClose,
  profile,
  onOpenAListDialog,
}: HeaderProfileModalProps) => {
  const [dragOffsetY, setDragOffsetY] = useState<number>(0);
  const { Link } = useEnvironmentContext();
  const isBelowTablet = useBreakpointValue({
    base: true,
    md: false,
  });

  const animationControls = useAnimation();
  const dragControls = useDragControls();

  const handleDragEnd = (_event: DragEvent<HTMLDivElement>, info: PanInfo) => {
    const dragDistanceY = info.offset.y;
    if (dragDistanceY >= 50) {
      setDragOffsetY(Math.abs(dragDistanceY) * -1);
      setTimeout(() => onClose());
    } else {
      setDragOffsetY(0);
      animationControls.start({ y: 0, speed: 150 });
    }
  };

  const isAList = profile?.loyalty?.tier === Consumer_Tier.AList;

  const ProfileLinks = () => {
    return (
      <>
        <Box
          background={
            isAList
              ? 'dark.Container/Neutral/Base'
              : 'light.Container/Brand/Mute 2'
          }
          width={'100%'}
        >
          <AListTier
            profile={profile}
            type={
              profile?.loyalty?.points
                ? getAListTierProgressType(profile?.loyalty?.points)
                : ConsumerTierAListTypes.Member
            }
            onOpenAListDialog={onOpenAListDialog}
          />
        </Box>
        <Box
          data-testid="links-wrapper"
          padding={'16px'}
          display={'grid'}
          gap={'16px'}
        >
          <Link
            data-testid="treatment-history-link"
            href={Routes.treatments}
            isStandalone
            variant="action"
            icon={<ChevronRightIcon />}
            flex={1}
          >
            <Box width={'100%'}>Treatment History</Box>
          </Link>
          <Link
            data-testid="personal-info-link"
            href={Routes.settingsProfileInfo}
            isStandalone
            variant="action"
            icon={<ChevronRightIcon />}
          >
            <Box width={'100%'}> Personal Info</Box>
          </Link>
          <Link
            data-testid="login-security-link"
            href={Routes.settingsLogin}
            isStandalone
            variant="action"
            icon={<ChevronRightIcon />}
          >
            <Box width={'100%'}>Login & Security</Box>
          </Link>
          <Link
            data-testid="notification-privacy-link"
            href={Routes.settingsCommunication}
            isStandalone
            variant="action"
            icon={<ChevronRightIcon />}
          >
            <Box width={'100%'}>Notifications & Privacy</Box>
          </Link>
        </Box>
      </>
    );
  };

  // MOBILE DRAWER
  if (isBelowTablet) {
    return (
      <Drawer onClose={onClose} isOpen={isOpen} placement="bottom">
        <DrawerOverlay
          data-testid={'profile-drawer-overlay'}
          pointerEvents="auto"
        />
        <DrawerMotion
          data-testid={'profile-drawer-content'}
          minHeight="calc(100% - 24px)"
          maxHeight="calc(100% - 24px)"
          borderTopRadius={16}
          pointerEvents="auto"
          drag="y"
          marginBottom={dragOffsetY}
          animate={animationControls}
          dragControls={dragControls}
          dragListener={false}
          dragConstraints={{ top: false, bottom: 200 }}
          dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
          dragMomentum={false}
          dragElastic={{ top: false, bottom: 0.5 }}
          whileTap={{ cursor: 'grabbing' }}
          onDragEnd={handleDragEnd}
          borderColor={
            isAList
              ? 'dark.Border/Neutral/Default 08'
              : 'light.Border/Neutral/Default 08'
          }
          backgroundColor={
            isAList
              ? 'dark.Container/Neutral/Base'
              : 'light.Container/Brand/Mute 2'
          }
        >
          <DrawerHeader
            data-testid={'profile-drawer-header'}
            display="flex"
            alignItems="flex-start"
            gap="8"
            borderColor={'dark.Border/Neutral/Default 08'}
            justifyContent={'space-between'}
            padding={'8px 16px 16px 16px'}
          >
            <Flex
              position="relative"
              justifyContent="space-between"
              style={{ touchAction: 'none' }}
              onPointerDown={(event: PointerEvent) => dragControls.start(event)}
              width={'100%'}
              borderBottomColor={
                isAList
                  ? `dark.Border/Neutral/Default 08`
                  : `light.Border/Neutral/Default 08`
              }
            >
              <Flex alignItems={'center'} gap={'8px'} paddingTop="16px">
                <Avatar
                  data-testid="profile-avatar"
                  name={`${profile?.firstName} ${profile?.lastName}`}
                  borderColor="transparent"
                  cursor="none"
                  // To avoid showing weird initials when user info hasn't loaded
                  getInitials={!profile ? () => '' : undefined}
                  backgroundColor={isAList ? '#1C1B1B' : undefined}
                  foregroundColor={isAList ? '#C3A45E' : undefined}
                  _hover={{ cursor: 'default' }}
                />
                <Box
                  data-testid={'profile-drawer-name'}
                  textStyle={`Body/Medium/Medium`}
                  color={
                    isAList
                      ? 'dark.Text/Neutral/Default'
                      : 'light.Text/Neutral/Default'
                  }
                >{`${profile?.firstName} ${profile?.lastName}`}</Box>
              </Flex>
              <Box
                width={56}
                height={2}
                position="absolute"
                top={8}
                left="50%"
                transform="translateX(-50%)"
                borderRadius="Circle"
                backgroundColor={`${
                  isAList ? 'dark' : 'light'
                }.Border/Neutral/Subtle 2`}
              />
              <IconButton
                data-testid={'profile-drawer-close-button'}
                _focusVisible={{
                  outlineColor: 'none',
                  color: isAList
                    ? 'dark.Text/Neutral/Default'
                    : 'light.Text/Neutral/Default',
                }}
                shape="circle"
                colorScheme="action"
                variant="ghost"
                aria-label="Close"
                icon={<CloseIcon />}
                top={'-8px'}
                right={'-12px'}
                color={
                  isAList
                    ? 'dark.Text/Neutral/Default'
                    : 'light.Text/Neutral/Default'
                }
                onClick={onClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody
            data-testid={'profile-drawer-body'}
            padding={0}
            background={`light.Container/Neutral/Base`}
          >
            <ProfileLinks />
            <Box
              padding={'16px'}
              background={`light.Container/Neutral/Base`}
              borderTop={`1px solid ${getColorToken(
                'Border/Neutral/Default 08',
                'light'
              )}`}
            >
              <Link
                data-testid="logout-link"
                href={Routes.logout}
                isStandalone
                variant="action"
                width={'100%'}
                color={getColorToken('Icon/Status/Error medium 3')}
                icon={
                  <LogoutIcon
                    color={getColorToken('Icon/Status/Error medium 3')}
                  />
                }
              >
                <Box width={'100%'}>Sign Out</Box>
              </Link>
            </Box>
          </DrawerBody>
        </DrawerMotion>
      </Drawer>
    );
  }

  // NON-MOBILE MODAL
  return (
    <Modal onClose={onClose} isOpen={isOpen} size={'xs'} isCentered={false}>
      <ModalContent
        data-testid="profile-modal-content"
        maxW={'360px'}
        top={'56px'}
        right={'20px'}
        containerProps={{
          justifyContent: 'flex-end',
        }}
        borderColor={
          isAList
            ? 'dark.Border/Neutral/Default 08'
            : 'light.Border/Neutral/Default 08'
        }
        background={
          isAList
            ? 'dark.Container/Neutral/Base'
            : 'light.Container/Brand/Mute 2'
        }
      >
        <ModalHeader
          data-testid="profile-modal-header"
          display="flex"
          alignItems="flex-start"
          gap="8"
          borderColor={'dark.Border/Neutral/Default 08'}
          justifyContent={'space-between'}
          padding={'16px'}
        >
          <Flex alignItems={'center'} gap={'8px'} paddingTop={'16px'}>
            <Avatar
              data-testid="profile-avatar"
              name={`${profile?.firstName} ${profile?.lastName}`}
              borderColor="transparent"
              cursor="none"
              // To avoid showing weird initials when user info hasn't loaded
              getInitials={!profile ? () => '' : undefined}
              backgroundColor={isAList ? '#1C1B1B' : undefined}
              foregroundColor={isAList ? '#C3A45E' : undefined}
              _hover={{ cursor: 'default' }}
            />
            <Box
              data-testid="profile-modal-name"
              textStyle={`Body/Medium/Medium`}
              color={
                isAList
                  ? 'dark.Text/Neutral/Default'
                  : 'light.Text/Neutral/Default'
              }
            >{`${profile?.firstName} ${profile?.lastName}`}</Box>
          </Flex>
          <ModalCloseButton
            data-testid="profile-modal-close-button"
            variant={'plain'}
            top={'8px'}
            color={
              isAList
                ? 'dark.Text/Neutral/Default'
                : 'light.Text/Neutral/Default'
            }
            _focusVisible={{
              outlineColor: 'none',
              color: isAList
                ? 'dark.Text/Neutral/Default'
                : 'light.Text/Neutral/Default',
            }}
          />
        </ModalHeader>
        <ModalBody
          data-testid="profile-modal-body"
          padding={0}
          background={`light.Container/Neutral/Base`}
        >
          <ProfileLinks />
        </ModalBody>
        <ModalFooter
          data-testid="profile-modal-footer"
          padding={'16px'}
          background={`light.Container/Neutral/Base`}
          borderRadius={'0 0 16px 16px'}
        >
          <Link
            data-testid="logout-link"
            href={Routes.logout}
            isStandalone
            variant="action"
            width={'100%'}
            color={getColorToken('Icon/Status/Error medium 3')}
            icon={
              <LogoutIcon color={getColorToken('Icon/Status/Error medium 3')} />
            }
          >
            <Box width={'100%'}>Sign Out</Box>
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { HeaderProfileModal };
